import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import PageWrapper from '../components/pageWrapper'

import { Row, Col } from 'react-bootstrap'
import { renderRichText } from '../lib/richTextRender'

class Contact extends React.Component {
  render() {
    const { site, allContentfulFeedback } = this.props.data

    const siteTitle = site.siteMetadata.title
    const [feedback] = allContentfulFeedback.edges

    return (
      <Layout>
        <PageWrapper>
          <Helmet title={siteTitle} />
          <div className="wrapper p-3">
            <div className="d-flex align-items-end mb-4">
              <div>
                <h2 className="font-weight-bold">{feedback.node.title}</h2>
                <p className="font-weight-bold">
                  {renderRichText(feedback.node.intro.json)}
                </p>
              </div>
              <div className="vl"></div>
            </div>
            <Row className="row">
              <Col md={6}>
                <div>{renderRichText(feedback.node.opinion2.json)}</div>
              </Col>
              <Col md={6}>
                <div className="flex-1">
                  {renderRichText(feedback.node.opinion3.json)}
                </div>
              </Col>
            </Row>
            <Row className="row">
              <Col md={6}>
                <div>{renderRichText(feedback.node.opinion4.json)}</div>
              </Col>
              <Col md={6}>
                <div className="flex-1">
                  {renderRichText(feedback.node.opinion1.json)}
                </div>
                <div>{renderRichText(feedback.node.opinion5.json)}</div>
              </Col>
            </Row>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

export default Contact

export const pageQuery = graphql`
  query feedbackPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulFeedback {
      edges {
        node {
          opinion1 {
            json
          }
          intro {
            json
          }
          opinion2 {
            json
          }
          opinion3 {
            json
          }
          opinion4 {
            json
          }
          opinion5 {
            json
          }
          title
        }
      }
    }
  }
`
